<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-select-mutilple-bank" centered hide-footer size="xl" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="bi bi-plus-circle text-primary me-1"></i> Select Bank Account For Payout #{{ updateData.payout_id }}
        </h5>
      </template>
      <div class="row align-items-top">
        <div class="col-12 col-lg-6">
          <h5 class="font-size-14 fw-medium mb-4 custom_top_data_bar p-3 rounded custom_top_data_barV2"> Merchant Details</h5>
          <div class="mb-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Merchant</label>
            <div class="col-lg-8 col-12 col-form-label py-0">
              <span v-if="updateData.merchant_info">#{{ updateData.merchant_info.merchant_id }} {{  updateData.merchant_info.merchant_name }}</span>
            </div>
          </div>
          <div class="mb-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Created Date</label>
            <div class="col-lg-8 col-12 col-form-label py-0">
              <span v-if="updateData.created_at">{{ updateData.created_at }}</span>
            </div>
          </div>

          <!-- <div class="mb-2 row">
            <label class="col-lg-4 col-12 col-form-label py-0">Status</label>
            <div class="col-lg-8 col-12 col-form-label py-0">
              <span v-if="updateData.status">
              <span
                class="badge bg-soft-purple rounded-pill font-size-14"
                v-if="updateData.status == 'processing'"
                >Processing</span
              >
              <span
                class="badge bg-soft-warning rounded-pill font-size-14"
                v-else-if="updateData.status == 'pending'"
                >Pending</span
              >
              <span
                class="badge bg-soft-info rounded-pill font-size-14"
                v-else-if="updateData.status == 'reserved'"
                >Reserved</span
              >
            </span>
            </div>
          </div> -->

          
        </div>
        <div class="col-12 col-lg-6 mb-2">
          
          <h5 class="font-size-14 fw-medium text-white mb-4 custom_top_data_bar px-3 py-3 custom_top_data_barV2"> Payout Info</h5>
          <div class="mb-2 row">
            <label class="col-lg-4 col-12 mb-1 mb-lg-0">Currency</label>
            <div class="col-lg-8 col-12">
              <span class="badge bg-soft-primary mb-2 mb-lg-0 d-inline-block font-size-14">{{ updateData.payout_currency }}</span>
            </div>
          </div>
          <!-- <div class="mb-1 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-4 col-12">Cryptocurrency</label>
            <div class="col-lg-8 col-12">{{ updateData.receiver_bank }}</div>
          </div>
          <div class="mb-1 row" v-else-if="updateData.receiving_bank">
            <label class="col-lg-4 col-12">Receiving Bank</label>
            <div class="col-lg-8 col-12">{{ updateData.receiving_bank }}</div>
          </div>
          
          
          <div class="mb-1 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-4 col-12">Network</label>
            <div class="col-lg-8 col-12">{{ updateData.receiver_account_name }}</div>
          </div>
          <div class="mb-1 row" v-else>
            <label class="col-lg-4 col-12">Beneficiary Name</label>
            <div class="col-lg-8 col-12">{{ updateData.receiver_account_name }}</div>
          </div>
          <div class="mb-1 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-4 col-12">Wallet Address</label>
            <div class="col-lg-8 col-12">{{ updateData.receiver_account_number }}</div>
          </div>
          <div class="mb-1 row" v-else>
            <label class="col-lg-4 col-12">Beneficiary Account No.</label>
            <div class="col-lg-8 col-12">{{ updateData.receiver_account_number }}</div>
          </div>
          <div v-if="updateData.extra_field_1">
            <div class="mb-1 row" v-if="updateData.extra_field_1.enabled">
              <label class="col-lg-4 col-12">{{ updateData.extra_field_1.name }}</label>
              <div class="col-lg-8 col-12">{{ updateData.extra_field_1.value }}</div>
            </div>
          </div>
          <div v-if="updateData.extra_field_2">
            <div class="mb-1 row" v-if="updateData.extra_field_2.enabled">
              <label class="col-lg-4 col-12">{{ updateData.extra_field_2.name }}</label>
              <div class="col-lg-8 col-12">{{ updateData.extra_field_2.value }}</div>
            </div>
          </div>
          <div v-if="updateData.extra_field_3">
            <div class="mb-1 row" v-if="updateData.extra_field_3.enabled">
              <label class="col-lg-4 col-12">{{ updateData.extra_field_3.name }}</label>
              <div class="col-lg-8 col-12">{{ updateData.extra_field_3.value }}</div>
            </div>
          </div> -->
          <div class="mb-2 row" v-if="updateData.payout_amount">
            <label class="col-lg-4 col-12 mb-1">Payout Amount</label>
            <div class="col-lg-8 col-12 fw-medium text-primary">{{ updateData.contract_currency }} <span v-if="updateData.payout_amount">{{ convertCurrencyFormat(updateData.payout_amount,true) }}</span> </div>
          </div>
          <!-- <div class="mb-1 row" v-if="updateData.payout_amount">
            <label class="col-lg-4 col-12">Total Charges</label>
            <div class="col-lg-8 col-12"> 
             <span class="fw-medium text-dark">{{ updateData.contract_currency }}<span v-if="updateData.payout_total_charges">
              {{ convertCurrencyFormat(updateData.payout_total_charges,true) }}</span></span>
              <small class="fw-normal d-block text-muted font-size-14">Payout Rate and Extra Charges ({{ updateData.payout_rate }}% + {{ updateData.contract_currency }} {{ updateData.payout_extra_charges }})</small>
           
            </div>
          </div>
          <div class="mb-1 row" v-if="updateData.payout_amount">
            <label class="col-lg-4 col-12">Amount Deducted</label>
            <div class="col-lg-8 col-12"> 
             <span class="fw-medium text-primary">{{ updateData.contract_currency }}
              <span v-if="updateData.amount_deducted">
                  {{convertCurrencyFormat(updateData.amount_deducted,true)}}
              </span>
            </span>
            </div>
          </div>

          <div class="mb-1 row" v-if="updateData.transaction_description">
            <label class="col-lg-4 col-12">Transaction Description</label>
            <div class="col-lg-8 col-12 text-muted">{{updateData.transaction_description}}</div>
          </div> -->

          <!-- <div class="mb-2 row" v-if="updateData.merchant_callback_url">
            <label class="col-lg-4 col-12 mb-1 mb-lg-2">Callback URL</label>
            <div class="col-lg-8 col-12 text-muted">{{updateData.merchant_callback_url}}</div>
          </div> -->
          
        </div>

        <hr>
        <!-- datatable -->
        <div class="col-12 mb-2">
          <div>
            <label class="d-inline-flex align-items-center">
              Filter:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Keyword..."
                class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <div class="col-12 mb-3">
            <div class="table-responsive mb-0">
              <!-- striped -->
                <b-table
                  class="text-nowrap align-middle mb-0 custom-datatable-table"
                  :items="bankAccountList"
                  striped
                  :fields="visibleFields()"
                  responsive="sm"
                  :per-page="perPage2"
                  :current-page="currentPage2"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :busy="loading"
                  show-empty
                >        
                <template>
                  <div class="text-center py-5 text-center text-muted" slot="table-busy">
                    <div
                      class="spinner-border text-secondary my-2"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <div>Loading...</div>
                  </div>
                </template>
                <template slot="empty">
                  <div class="text-center text-muted py-5">
                    <i class="bi bi-bank me-1"></i> No Data Available
                  </div>
                </template>
                <template slot="emptyfiltered">
                  <div class="text-center text-muted py-5">
                    <i class="bi bi-bank me-1"></i> There are no records matching your keyword
                  </div>
                </template>
                <!-- <template #cell(index)="data">
                  {{ data.index + 1 }}
                  Whole Index
                  {{ currentPage2 * perPage2 - perPage2 + data.index }}
                </template>

                <template #cell(name)="data">
                  {{data.value}}
                </template> -->
                <template #cell(amount)="data">
                
                  <!-- fields.find(e=>e.key=='current_bank_balance').sortable=true -->
                  <div class="input-group input-group-sm" style="min-width: 120px; max-width: 150px" >
                      <input type="text" inputmode="decimal" class="form-control text-center font-size-14" placeholder="Amount" v-model="data.item.amount" @input="inputNumberOnlyV2($event, 'bankAccountList', 'amount', data.item.custom_index), checkInputAmout()" :disabled="modalLoading || loading" required>
                      <span class="input-group-text bg-info border-info text-white cursor-pointer" @click="data.item.amount=updateData.payout_amount, checkInputAmout()">Max</span>
                    </div>
                </template>

                <template #cell(current_bank_balance)="data">
                  {{convertCurrencyFormat(data.item.current_bank_balance,true)}}
                </template>

                <template #cell(upi_accounts)="data">
                  <b-dropdown variant="light" size="sm" :disabled="!data.item.upi_accounts.length">
                      <template v-slot:button-content>
                        <span class="text-primary fw-medium font-size-13">
                          UPI Accounts ({{data.item.upi_accounts.length}})
                        <i class="mdi mdi-chevron-down"></i>
                        </span>
                        
                      </template>
                      <b-dropdown-item
                        class="d-flex align-items-center font-size-13"
                        href="javascript:void(0)"
                        v-for="(v, i) in data.item.upi_accounts"
                        :key="i"
                        > 
                        <i class="bi bi-check-circle text-primary me-2"></i>
                          <span> 
                            {{ v.upi_id }}
                          </span>
                        </b-dropdown-item
                      >
                    </b-dropdown>
                </template>
                
                <template #cell(today_in_amount)="data">
                  
                  <div class="text-success">
                    {{ convertCurrencyFormat(data.item.today_in_amount,true)}} 
                  </div>
                  
                </template>

                <template #cell(active)="data">
                  <span v-if="data.item.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                    <span v-else-if="data.item.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                </template>


                <template #cell(action)="data">
                    <div v-if="create_modify_permission">
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="edit(data.item)" :disabled="disabled">
                      <i class="uil uil-edit"></i></button>
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded ms-2" @click="remove(data.item)" :disabled="disabled">
                      <i class="uil uil-trash-alt"></i></button>
                    </div>
                </template>

                <template #cell(bank_name)="data">
                  {{ data.item.bank_name  }} 
                  <div>  {{ data.item.ifsc_code  }} </div>
                </template>

                <template #cell(id)="data">
                  #{{ data.item.id }}
                </template>
              </b-table>
            </div>
            
        </div>
        <div class="col-12 mb-3" v-if="bankAccountList.length && !loading">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-end"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  size="sm"
                  v-model="currentPage2"
                  :total-rows="totalData"
                  :per-page="perPage2"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <!-- datatable end -->
        <div class="col-12">
          <div class="shadow-none p-3 rounded text-danger fw-medium  border border-danger d-flex font-size-13 align-items-center">
              <div><i class="bi bi-exclamation-circle-fill me-2 d-flex font-size-18"></i></div> 
              <div>Please note that only the filled input amount will be selected for payout. Please verify that the selected bank account matches the filled input amount.
              <br>请注意，仅选择填写的金额将用于下发与支出。请验证所选银行账户与填写的金额是否匹配。</div>
          </div>
        
        </div>
        <div class="col-12">
          <hr>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-select-mutilple-bank'].hide()">Cancel</button>
            <button type="button" class="btn fw-medium btn-info" @click="update" :disabled="modalLoading || disabled">
              <span v-if="seletedStatus=='reserved'">
                <span v-if="modalLoading"> Reserving...</span>
                <span v-else> Reserved </span>
              </span>
              <span v-else>
                <span v-if="modalLoading"> Updating...</span>
                <span v-else> Select </span>
              </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import {
  required
} from "vuelidate/lib/validators";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
// const validWebsiteUrl = (value) => {
//   console.log(value);
//   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
//   return regex.test(value);
// }
// const validUsername = (value) => {
//   const regex = /^[a-z0-9]+$/;
//   return regex.test(value);
// };
//  const equalDefault = (value) => {
//   return value !== -1;
//   }
/**
 * Starter page
 */
export default {
  components: {
    Common,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      updateData: {},
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      seletedStatus: "",
      currentImageSelected: "front",
      fileCompressing: false,
      returnData: [],
      selectedBankAccount:[],
      bankAccountList:[],
      totalBankAccountSeletedAmount:0,
      formSuccessData: {
        transactionId: "",
        transactionReceipt: [],
        transactionRemarks: "",
        bankAccount: "",
      },
      formRejectedData: {
        reasonForRejected: "",
      },
      uploadProgress:0,
      currentFiles: 0,
      uploadHowManyPerTime:0,
      maxFiles: 3,
      //datatable
      totalData:0,
      totalRows: 1,
      currentPage2: 1,
      perPage2: 20,
      filter: "",
      filterOn: [],
      sortBy: "active",
      sortDesc: true,
      fields: [
         {
          key: "amount",
          label:"Payout Amount",
          thClass:"user-select-none",
          tdClass: "align-middle",
        },
        {
          key: "bank_name",
          label:"Bank Name/IFSC Code",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bank_account_no",
          label:"Bank Acc. No",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
       
        {
          key: "bank_account_name",
          label:"Bank Acc. Name",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        // {
        //   key: "today_in_amount",
        //   label:"Today In Amount",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   formatter: (value) => {
        //     if (value) {
        //       return this.convertCurrencyFormat(value,true);
        //     } else {
        //       return 0; // or any default value if upi_accounts is not an array
        //     }
        //   },
        //   sortable: true,
        // },
        {
          key: "today_out_amount",
          label:"Today Out Amount",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
       
        {
          key: "current_bank_balance",
          label:"Bank Acc. Balance",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,false);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "active",
          label:"Status",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: false,
        }
        
      ]
    };
  },
  validations: {
    formSuccessData: {
      // transactionReceipt: {
      //   required,
      // },
      transactionId: {
        required,
      },
      bankAccount: {
        required,
      },
    },
    formRejectedData: {
      reasonForRejected: {
        required,
      }
    }
  },
  middleware: "authentication",
  async mounted() {
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
    await this.reload();
  },
  created() {},
  methods: {
    disabledInputAmount(amount, max){
      if (this.totalBankAccountSeletedAmount!==parseFloat(max)){
        console.log(amount,this.totalBankAccountSeletedAmount, max)
        return false;
      }else {
        return true;
      }
    },
    checkInputAmout(){
      console.log("checkInputAmout")
      var totalAmount = 0;
      this.bankAccountList.forEach(element => {
        if (parseFloat(element.amount)>0){
          totalAmount += parseFloat(element.amount)
        }
      });
      this.totalBankAccountSeletedAmount = totalAmount
    },
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage2 = 1;
    },
    async onChangePDF(e) {
      console.log(this.currentFiles,this.maxFiles);
      console.log(e.target.files.length);
      if ((e.target.files.length+this.currentFiles)> this.maxFiles) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `The system allows a maximum of ${this.maxFiles} file uploads.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
        e.target.value = '';
        return; // Abort further processing if the file limit is exceeded
      }

      // Iterate over each file
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        // Check if the file size is greater than 5MB
        if (file.size > 5 * 1024 * 1024) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `The file ${file.name} exceeds the 5MB size limit.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          e.target.value = '';
          return; // Abort further processing if any file size exceeds 5MB
        }

        // Upload each file
        await this.uploadFile(file, e.target.files.length);
      }
    },
    convertFileSize(fileSize) {
      var formattedFileSize = ''
      if (fileSize < 1024) {
        formattedFileSize = fileSize + ' bytes';
      } else if (fileSize < 1048576) { // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + ' KB';
      } else if (fileSize < 1073741824) { // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + ' MB';
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + ' GB';
      }
      return formattedFileSize
    },
    async uploadFile(file, howManyFiles) {
      this.$Progress.start();
      this.disabled = true;
      const bodyFormData = new FormData();
      bodyFormData.append('accessToken', this.accessToken);
      bodyFormData.append('accessUsername', this.accessUsername);
      bodyFormData.append('media', file);
      var postUrl = appConfig.APIHostAdmin + 'controller/media/saveMediaFile'
      try {
        const response = await axios.post(postUrl, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        const resData = response.data;
        if (resData.status === 200) {
          // Add the URL to your array or handle the success as needed
          this.formSuccessData.transactionReceipt.push(
            {
              'file_name':file.name,
              'file_size': this.convertFileSize(file.size),
              'link': resData.media_path
            })
          
          this.currentFiles = this.currentFiles + 1
          this.uploadHowManyPerTime = this.uploadHowManyPerTime + 1
          if (howManyFiles==this.uploadHowManyPerTime){
            document.getElementById('formFile').value = ''
            this.uploadHowManyPerTime = 0
          }
        } else if (resData.status === 440) {
          // Handle 440 status code
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          localStorage.clear();
          this.$router.push({
            path: '/login',
          });
        } else {
          // Handle other status codes
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
        }
      } catch (error) {
        // Handle the error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
      } finally {
        this.disabled = false;
        this.uploadProgress = 0;
        this.$Progress.finish();
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formSuccessData[parentModel] = numericValue;
    },
    inputNumberOnlyV2(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][index][childModel] = numericValue
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    async getBankAccountList(){
        this.bankAccountList = []
        console.log("getBankAccountList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "99999");
        bodyFormData.append("keyword", "");
        bodyFormData.append("sortBy", "current_bank_balance_desc");
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/bankAccount/getAllBankAccountList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              this.selectedBankAccount = []
              this.bankAccountList = []
              resData.data.forEach((element, index) => {
                this.bankAccountList.push({
                    amount:"",
                    account_db_code: element.account_db_code,
                    id: element.id,
                    currency: element.currency,
                    bank_name: element.bank_name,
                    bank_account_name: element.bank_account_name,
                    bank_account_no: element.bank_account_no,
                    ifsc_code: element.ifsc_code,
                    transaction_limit: element.transaction_limit,
                    today_in_amount: element.today_in_amount,
                    today_out_amount: element.today_out_amount,
                    current_bank_balance: element.current_bank_balance,
                    active: element.active,
                    custom_index: index,
                    keyword:`${element.bank_name} - ${element.bank_account_no} (${element.bank_account_name})`
                  });
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    showModal() {
      this.$refs['modal-select-mutilple-bank'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.getBankAccountList();
    },
    startFiles() {
      this.fileCompressing = true;
    },
    currentImageSelectedFunc(index) {
      console.log("seleted" + index);
      this.currentImageSelected = index;
      if (this.currentImageSelected == 'front') {
        this.$refs.fileInput.trigger();
      } else if (this.currentImageSelected == 'back') {
        this.$refs.fileInput2.trigger();
      }
    },
    viewAllFiles(res) {
      this.fileCompressing = false;
      for (let n of res) {
        // this.ShakeCampaignBanner = n.img;
        const myFile = new File([this.DataURIToBlob(n.img)], n.name, {
          type: "image/jpeg",
        });
        if (this.currentImageSelected == 'front') {
          this.formSuccessData.transactionReceipt = n.img;
          this.formSuccessData.transactionReceiptFile = myFile;
          this.formSuccessData.transactionReceiptFilename = n.name
          console.log(myFile)
        } else if (this.currentImageSelected == 'back') {
          // this.formSuccessData.transactionReceipt2 = n.img;
          // this.formSuccessData.transactionReceiptFile2 = myFile;
          // this.formSuccessData.transactionReceiptFilename2 = n.name
          // console.log(myFile)
        }
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], {
        type: mimeString
      });
    },
    
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.seletedStatus = ""
      this.formRejectedData.reasonForRejected = ""
      this.formSuccessData.transactionId = ""
      this.formSuccessData.transactionRemarks = ""
      this.formSuccessData.transactionReceipt = []
      this.formSuccessData.bankAccount=""
      this.selectedBankAccount = []
      this.currentPage2 = 1
      this.totalBankAccountSeletedAmount =0
    },
    changeStatus() {
      this.selectedBankAccount = []
      this.formSuccessData.bankAccount=[]
    },
    updateBankAccount() {
      if (this.seletedStatus=='complete' && this.selectedBankAccount.account_db_code !=='') {
        this.formSuccessData.bankAccount=this.selectedBankAccount
        this.formSuccessData.transactionRemarks = `${this.selectedBankAccount.bank_account_name} - ${this.selectedBankAccount.bank_name} 执行下发 ${this.convertCurrencyFormat(this.updateData.payout_amount,true)}`
      }else{
        this.formSuccessData.bankAccount=""
        this.formSuccessData.transactionRemarks = ""
      }
    },
    update(){
      if (parseFloat(this.totalBankAccountSeletedAmount)!==parseFloat(this.updateData.payout_amount)){
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `The selected bank account for the total payout amount must match the payout amount ${this.convertCurrencyFormat(this.updateData.payout_amount,true)}` ,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          return
      }
      this.$refs['modal-select-mutilple-bank'].hide();
      var updateBackToParent = []
      this.bankAccountList.forEach(element => {
        if (parseFloat(element.amount)>0){
          updateBackToParent.push(element)
        }
      });
      this.$emit('updateBankInfo',updateBackToParent);
    },
    updateSettlement() {
      this.modalSubmit = true
      if (this.seletedStatus == 'rejected') {
        this.$v.formRejectedData.$touch();
        if (this.$v.formRejectedData.$invalid) {
          return;
        }
      }
      if (this.seletedStatus == 'complete') {
        this.$v.formSuccessData.$touch();
        if (this.$v.formSuccessData.$invalid) {
          return;
        }
      }
      this.$Progress.start();
      this.modalLoading = true;
      var bodyFormData = new FormData();
      const files = []
      this.formSuccessData.transactionReceipt.forEach(element => {
        files.push(element.link)
      });
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("payoutId", this.updateData.payout_id);
      bodyFormData.append("payoutDbCode", this.updateData.payout_db_code);
      bodyFormData.append("status", this.seletedStatus);
      if (this.seletedStatus == 'rejected') {
        bodyFormData.append("rejectedReason", this.formRejectedData.reasonForRejected);
      }
      if (this.seletedStatus == 'complete') {
        bodyFormData.append('bankAccount', JSON.stringify(this.formSuccessData.bankAccount));
        bodyFormData.append("transactionId", this.formSuccessData.transactionId);
        bodyFormData.append("transactionRemark", this.formSuccessData.transactionRemarks);
        bodyFormData.append("bankReceiptList", JSON.stringify(files));
      }
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/updatePayoutStatus',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        this.returnData = []
        var resData = response.data;
        if (resData.status == 200) {
          console.log(resData)
          Swal.fire({
            icon: 'success',
            title: 'Payout Status Updated',
            html: 'Your payout status has been successfully updated!',
            confirmButtonColor: '#FA6541',
            confirmButtonText: this.$t('siteLang.Done')
          }).then(() => {
            this.$refs['modal-select-mutilple-bank'].hide()
            if (this.seletedStatus == 'pending') {
              this.$emit('callParentFunction');
            } else if (this.seletedStatus == 'reserved') {
              this.$emit('callParentFunction');
            } else if (this.seletedStatus == 'complete') {
              this.$router.push({
                name: "payout",
              });
            } else if (this.seletedStatus == 'rejected') {
              this.$router.push({
                name: "payout",
              });
            }
          })
        } else if (resData.status == 401) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.$refs.commonFunc.clearData()
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.modalLoading = false;
        this.modalSubmit = false
        this.$Progress.finish();
      }).catch((error) => {
        this.modalLoading = false;
        this.modalSubmit = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          const permission = data.modules.filter(e => e.name==='payout')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "payout" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>